import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation, Auth } from 'aws-amplify';
//import * as queries from '../graphql/queries.js';
import * as mutations from '../graphql/mutations';


class Authenticate extends Component {

    constructor(props){
        super(props)

        this.state = {
            authNumber: "",
            groupDisplay: '',
            authorizing: false,
            userName: '',
            password: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.orgUnits = [];

    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    signOut = () => {
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));
        this.props.history.push('/SignIn');
    }


    async handleSubmit(event) {
        event.preventDefault();

        this.setState({authorizing: true});

        //auth number from form
        let orgid = this.state.authNumber.replace(/\s+/g, '');

        //username grabbed from cognito
        let username = this.userName;

        let forwardHome = true;

        //check to find a match
        function authCheck() {
            const apiName = 'EntAuth';
            const path = '/items';

            const myInit = {
                body: {
                    orgid: orgid,
                    username: username
                }
            };
            return API.put(apiName, path, myInit);
        }

        let response = await authCheck();

        // catch errors from api
        let status = response.success;
        let group = response.group;

        if(status === 'nomatch'){
            this.setState({authorizing: false});
            alert("No record of this organization please make sure the access id number is correct.")
            return
        };

        if(status === 'fail'){
            this.setState({authorizing: false})
            alert("Error when authenticating user please try again.")
            return
        };

        try {
            await Auth.signIn(this.state.userName, this.state.password);
        } catch (error) {
            if(error.code === 'UserNotFoundException'){
                this.setState({authorizing: false});
                alert('Username not found.')
                return
            }
            if(error.code === 'NotAuthorizedException'){
                this.setState({authorizing: false});
                alert('Incorrect password.')
                return
            }
            if(error.code === 'UserNotConfirmedException'){
                this.setState({authorizing: false});
                alert('Account email has not been verified.')
                this.props.history.push('/Verify');
            }
        }

        if(status === 'success'){
            forwardHome = false

            let level = group.level.S
            let enterprise = group.entName.S
            let division =  group.divisionName.S
            let organization = group.orgName.S

            //let entInfo = await API.graphql(graphqlOperation(queries.getEnt, { entName: enterprise }));

            //let entConnection = entInfo.data.getEnt.id

            const addUserEnt = `mutation CreateEntUser {
                createEntUser(input: {
                userName: "${username}"
                level: "${level}"
                entName: "${enterprise}"
                divName: "${division}"
                orgName: "${organization}"
                entUserEntId: "${enterprise}"
                }) {
                id
                }
            }`

            //add user if not found in User db
            try {
                await API.graphql(graphqlOperation(addUserEnt));
                forwardHome = true
            } catch(error) {
                var userFound = error.errors[0].errorType
                if(userFound === "DynamoDB:ConditionalCheckFailedException"){
                alert('User was already in the system.')
                }
                console.log(error)
            };

        }

        if(status === 'nowledge'){

            const addUser = `mutation CreateUser {
                createUser(input: {
                userName: "${username}"
                groupName: "${group}"
                userGroupId: "${group}"
                }) {
                id
                }
            }`

            //add user if not found in User db
            try {
                await API.graphql(graphqlOperation(addUser));
            } catch(error) {
                var userFoundNow = error.errors[0].errorType
                if(userFoundNow === "DynamoDB:ConditionalCheckFailedException"){
                    //alert('user was already in the system')
                }
            };

            const getUnits = `query GetUnits {
                getGroup(groupName: "${group}") {
                id
                units {
                    items {
                    id
                    serial
                    nickName
                    version
                    }
                }
                }
            }`

            //return from Unit db all units attached to the organization
            try{
                let returnUnits = await API.graphql(graphqlOperation(getUnits));
                this.orgUnits = returnUnits.data.getGroup.units.items
            } catch(error) {
                console.log(error)
            };

            async function addButtons(orgUnits, userName) {
                orgUnits.map(async (unit) => {
                    await API.graphql(graphqlOperation(mutations.createMultidevice, { input: { group: userName.toLowerCase(), location: unit.serial,  title: unit.nickName, version:  unit.version }}));
                })
            }

            try{
                await addButtons(this.orgUnits, this.userName);
                forwardHome = true
                } catch (error) {
                    forwardHome = false
                }
            }

            let orgCopy = this.orgUnits.slice()
            orgCopy.push({'serial': "marketing", 'nickName': "marketing", 'version': 2})

            async function addEmailList(orgUnits, emailAddress, group ) {
                orgUnits.forEach(async device => {

                    if(device.version === 2){
                    const apiNameCheck = 'AlarmList';
                    const pathCheck = '/items';
                
                    const mutationInit = {
                        queryStringParameters: {
                            email: emailAddress,
                            nickname: device.nickName,
                            serial: device.serial,
                            organization: group,
                            status: 'unsubscribed'
                        }
                    }
                
                    try{
                        await API.post(apiNameCheck, pathCheck, mutationInit);
                    } catch(error) {
                        console.log(error)
                        alert('an error occurred accessing the inventory database please try again')
                        return
                    }
                    }

                })
            }
            
            try{
                await addEmailList(orgCopy, this.email, group);
                forwardHome = true
            } catch (error) {
                forwardHome = false
            }


            if(forwardHome){
                this.props.history.push('/Intro');
            } else {
                alert('Error adding units.')
            }

    }

    async componentDidMount(){
        let user = await Auth.currentAuthenticatedUser();
        this.email = user.attributes.email

        //const userGroup = user.signInUserSession.accessToken.payload["cognito:groups"];

        this.userName = user.username;

        if(this.userName === ''){
            alert('Must be logged in as a user after verifying through email.')
        }

        this.setState({userName: this.userName});

    }

    render(){
        var backIcon = require('../Back-01.png');

        return(
            <div className="App">
                <div className="Nav-header">
                    <Link style={{textDecoration: 'none'}} to="/SignIn">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                    </Link>
                    <button onClick={this.signOut} className="Settings-button">signout</button>
                </div>

                {this.state.authorizing &&
                    <div>
                        <div className='Two-space'/>
                        <div className="Settings-wrapper">
                            <div className='One-space'/>
                            <div className="Loading-buttonstatus">Loading...</div>
                            <div className='One-space'/>
                        </div>
                    </div>
                }

                {!this.state.authorizing &&
                    <div>
                        <div className="Title">Authenticate Account</div>

                        <div className="Settings-wrapper">
                            <div className="form-name">username</div>
                            <div className="Title">{this.state.userName}</div>
                            <div className="form-name">user password</div>
                            <input value={this.state.password} name="password" className="Reading-name" type="password" onChange={this.handleChange}/>

                            <form onSubmit={this.handleSubmit}>
                                <div className='Two-space'/>
                                <div className="form-name">enter ACCESS ID number</div>
                                <input value={this.state.authNumber} name="authNumber" className="Reading-name" type="text" onChange={this.handleChange}/>
                                <div className='One-space'/>
                                <input type="submit" className="Add-button" value="submit"/>
                            </form>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Authenticate

import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import aws_exports from '../aws-exports';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { Link } from 'react-router-dom';
import * as queries from '../graphql/queries.js';
import NowButtons from "../components/NowButtons";
import NowOrgButtons from "../components/NowOrgButtons";
import SyncDevicesButton from "../components/SyncDevicesButton";


Amplify.configure(aws_exports);


class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            devices: [],
            organizations: [],
            refresh: false,
            group: '',
            noDevice: false
        }
        this.refreshButton = this.refreshButton.bind(this);
    }


    async refreshButton(event) {
        event.preventDefault();

        await Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then( async user => {
            const userGroup = user.signInUserSession.accessToken.payload["cognito:groups"];

            if(userGroup === undefined){
                this.props.history.push('/Authenticate')
            }

            let groupCheck = userGroup[0];

            this.setState({group: groupCheck})

            let zeroDevices = false

            if (groupCheck === "enerstar"){
                try {
                    let data = await API.graphql(graphqlOperation(queries.listDevices, { limit: 1000 }));
                    let sortedSerial = data.data.listDevices.items.sort((a, b) => a.location.localeCompare(b.location, undefined, { numeric: true }))
                    let sortedDevices = sortedSerial.sort((a, b) => a.group.localeCompare(b.group, undefined, { numeric: true }))
                    let orgArrayOnly = sortedDevices.map((orgObject) => orgObject.group)
                    let reducedOrgs = [...new Set(orgArrayOnly)];

                    this.setState({
                        devices: sortedDevices,
                        refresh: false,
                        group: "enerstar",
                        organizations: reducedOrgs
                    })
                } catch(error) {
                    console.log(error)
                    if(error.errors[0].message === 'Network Error'){
                        this.setState({refresh: true})
                        return
                    } else {
                        this.setState({refresh: true})
                        return
                    }
                    
                }
            }

            if (groupCheck === "multiloc"){
                let queryUpper = user.username
                let queryName = queryUpper.toLowerCase()

                try{
                    let data = await API.graphql(graphqlOperation(queries.listMultidevices, {
                        limit: 1000,
                        filter: {
                            group:{
                                eq: queryName
                            }
                        }
                    }));

                    if(data.data.listMultidevices.items.length === 0){
                        zeroDevices = true
                        this.setState({
                            noDevice: zeroDevices,
                            group: "multiloc",
                            refresh: false
                        })
                    } else {
                        data.data.listMultidevices.items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))

                        this.setState({
                            devices: data.data.listMultidevices.items,
                            refresh: false,
                            group: "multiloc",
                            noDevice: zeroDevices
                        })
                    }
                } catch(error) {
                    console.log(error)
                    if(error.errors[0].message === 'Network Error'){
                        this.setState({refresh: true})
                        return
                    } else {
                        this.setState({refresh: true})
                        return
                    }
                }
            }

        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
    );
    }

    //user info and query
    async componentDidMount(){

        await Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then( async user => {
            const userGroup = user.signInUserSession.accessToken.payload["cognito:groups"];

            if(userGroup === undefined){
                this.props.history.push('/Authenticate')
            }

            let groupCheck = userGroup[0];

            this.setState({group: groupCheck})

            if ((groupCheck === "ent") || (groupCheck === "div") || (groupCheck === "org")){
                this.props.history.push('/DeviceSync');
            }

            let zeroDevices = false

            if (groupCheck === "enerstar"){
                try {
                    let data = await API.graphql(graphqlOperation(queries.listDevices, { limit: 1000 }));
                    let sortedSerial = data.data.listDevices.items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
                    let sortedDevices = sortedSerial.sort((a, b) => a.group.localeCompare(b.group, undefined, { numeric: true }))
                    let orgArrayOnly = sortedDevices.map((orgObject) => orgObject.group)
                    let reducedOrgs = [...new Set(orgArrayOnly)];

                    this.setState({
                        devices: sortedDevices,
                        refresh: false,
                        group: "enerstar",
                        organizations: reducedOrgs
                    })
                } catch(error) {
                    console.log(error)
                    if(error.errors[0].message === 'Network Error'){
                        alert('network error check your internet connection')
                        this.setState({refresh: true})
                        return
                    } else {
                        this.setState({refresh: true})
                        return
                    }
                    
                }
            }

            if (groupCheck === "multiloc"){
                let queryUpper = user.username
                let queryName = queryUpper.toLowerCase()

                try{
                    let data = await API.graphql(graphqlOperation(queries.listMultidevices, {
                        limit: 10000,
                        filter: {
                            group:{
                                eq: queryName
                            }
                        }
                    }));

                    if(data.data.listMultidevices.items.length === 0){
                        zeroDevices = true
                        this.setState({
                            noDevice: zeroDevices,
                            group: "multiloc",
                            refresh: false
                        })
                    } else {
                        data.data.listMultidevices.items.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))

                        this.setState({
                            devices: data.data.listMultidevices.items,
                            refresh: false,
                            group: "multiloc",
                            noDevice: zeroDevices
                        })
                    }
                } catch(error) {
                    console.log(error)
                    if(error.errors[0].message === 'Network Error'){
                        alert('network error check your internet connection')
                        this.setState({refresh: true})
                        return
                    } else {
                        this.setState({refresh: true})
                        return
                    }
                }
            }
        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
    );

    }

  render() {
    var imageName = require('../Nowledge-Logo_ALL-WHT.png');
    var menuIcon =require('../icons8-menu-100.png')

    return (
        <div className="App">
            {
                <div className="Nav-header">
                <img className="Logo-user" src={imageName} alt="nowledge" />
                {
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: '/MainSettings' }}>
                        <button className="Settings-button">
                            <img className="Settings-icon" src={menuIcon} alt="settings" />
                        </button>
                    </Link>
                }
                </div>
            }

            { this.state.refresh &&
                <div>
                    <div className='One-space'/>
                    <div className="Settings-wrapper">
                        <div className='One-space'/>
                        <div className="Loading-buttonstatus">Could Not Load Devices</div>
                        <div className="Timestamp-button">Hit Refresh To Try Again</div>
                        <div className='One-space'/>
                        <button onClick={this.refreshButton} className="Icon-submit">
                            <div>refresh</div>
                        </button>
                        <div className='One-space'/>
                    </div>
                </div>
            }
            { this.state.noDevice &&
                <div>
                    <div className='Two-space'/>
                    {
                        <div className="Settings-wrapper">
                        <div className='One-space'/>
                        <div className="Loading-buttonstatus">No Devices Found</div>
                        <div className="Timestamp-button">Onboarded Devices Will Appear Here</div>                        
                        <div className='Two-space'/>
                        </div>
                    }
                    {
                        <div>
                            <div className='Two-space'/>
                            <div className='location-dev'>Check for devices by syncing</div>
                            <SyncDevicesButton />
                        </div>
                    }
                    

                </div>
            }
            <br/>
            { !this.state.refresh && !this.state.noDevice &&
                <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Overview', state: { data: this.state.devices, group: this.state.group } }}>
                    <div className="Overviewbutton-home">
                        <button className="Overview-button">
                            <div>overview</div>
                        </button>
                    </div>
                </Link>
            }

            { this.state.group === "enerstar" &&                
                <NowButtons data={this.state.devices} orgs={this.state.organizations}/>
            }
            { this.state.group ==="multiloc" &&
                <NowOrgButtons data={this.state.devices}/>
            }
            <div className='Eight-space'/>
        </div>
    );
  }
}

export default Home
// Mokena Boys

import React, { Component } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';

class AlarmSignUp extends Component {

    constructor(props){
        super(props);

        this.state = {
            index: '',
            loading: true,
            devicesState: []
        }

        this.fetchAlerts = this.fetchAlerts.bind(this);
    }

    async fetchAlerts(){
        const apiNameCheck = 'AlarmList';
        const pathCheck = '/items';
        let devices = {};

        const myInitCheck = {
            queryStringParameters: {
                name: this.email
            }
        };
        try{
            devices = await API.get(apiNameCheck, pathCheck, myInitCheck);
        } catch(error) {
            console.log(error)
            alert('an error occurred')
            return
        }

        let alarmsList = devices.return
        let sortedNickname = alarmsList.sort((a, b) => a.nickname.S.localeCompare(b.nickname.S, undefined, { numeric: true }))
        let sortedOrgs = sortedNickname.sort((a, b) => a.organization.S.localeCompare(b.organization.S, undefined, { numeric: true }))

        return sortedOrgs
    }

    async buttonClicked(device, status, index) {

        this.setState({
            index: index
        })
        const apiNameCheck = 'AlarmList';
        const pathCheck = '/items';

        const mutationInit = {
            queryStringParameters: {
                email: device.email.S,
                username: device.username.S,
                id: device.id.S,
                nickname: device.nickname.S,
                serial: device.serial.S,
                organization: device.organization.S,
                status: status
            }
        }

        try{
            await API.put(apiNameCheck, pathCheck, mutationInit);
        } catch(error) {
            console.log(error)
            alert('an error occurred accessing the inventory database please try again')
            return
        }

        let emailAlerts = await this.fetchAlerts()

        this.setState({
            devicesState: emailAlerts,
            loading: false,
            index: ''
        })

    }

    async componentDidMount() {

        let user = await Auth.currentAuthenticatedUser();
        this.email = user.attributes.email
        this.userGroup = user.signInUserSession.accessToken.payload["cognito:groups"][0]
        this.userName = user.username

        let group = await API.graphql(graphqlOperation(queries.getUser, { userName: this.userName }));
        this.orgName = group.data.getUser.groupName

        let emailAlerts = await this.fetchAlerts()

        let devicesFound = []

        if (this.userGroup === "enerstar"){
            try {
                let data = await API.graphql(graphqlOperation(queries.listDevices, { limit: 1000 }));

                devicesFound = data.data.listDevices.items
            } catch(error) {
                console.log(error)
                if(error.errors[0].message === 'Network Error'){
                    alert('network error check your internet connection')
                    this.setState({refresh: true})
                    return
                } else {
                    alert('error fetching devices')
                    return
                }
                
            }
        }

        if (this.userGroup === "multiloc"){
            let queryUpper = user.username
            let queryName = queryUpper.toLowerCase()

            try{
                let data = await API.graphql(graphqlOperation(queries.listMultidevices, {
                    limit: 10000,
                    filter: {
                        group:{
                            eq: queryName
                        }
                    }
                }));

            devicesFound = data.data.listMultidevices.items
                
            } catch(error) {
                console.log(error)
                if(error.errors[0].message === 'Network Error'){
                    alert('network error check your internet connection')
                    return
                } else {
                    alert('error fetching devices')
                    return
                }
            }
        }

        let newDevices = []

        devicesFound.forEach(device => {
            let found = false
            if(device.version === 2){
                emailAlerts.forEach(alertEntry => {
                    if(alertEntry.serial.S === device.location){
                        found = true
                    } 
                })
                if(!found){
                    newDevices.push(device)
                }
            
            }
        });

        let addNewDevices = async() => {
            await Promise.all(newDevices.map(async (device) => {
                const apiNameCheck = 'AlarmList';
                const pathCheck = '/items';
                let orgSend = ''

                if(this.userGroup === 'enerstar'){
                    orgSend = device.group
                } else {
                    orgSend = this.orgName
                }
            
                const mutationInit = {
                    queryStringParameters: {
                        email: this.email,
                        username: this.userName,
                        nickname: device.title,
                        serial: device.location,
                        organization: orgSend,
                        status: 'unsubscribed'
                    }
                }
            
                try{
                    await API.post(apiNameCheck, pathCheck, mutationInit);
                } catch(error) {
                    console.log(error)
                    alert('an error occurred accessing the inventory database please try again')
                    return
                }
            }))
        }

        if(newDevices.length !== 0) {
            try{
                await addNewDevices()

                emailAlerts = await this.fetchAlerts()

                this.setState({
                    devicesState: emailAlerts,
                    loading: false
                })

            } catch(error) {
                console.log(error)
            }
        } else {
            this.setState({
                devicesState: emailAlerts,
                loading: false
            })
        }
    }




    render(){
        var backIcon = require('../Back-01.png');
        return (
            <div className='App'>
                {
                    <div className="Nav-header">
                        <Link style={{textDecoration: 'none'}} to="/MainSettings">
                        <button className="Back-wrap">
                            <img className="Back-button" src={backIcon} alt="back"/>
                        </button>
                        </Link>
                    </div>
                }
                <div className='Two-space'/>
                {
                    <div>
                        <div className='title-one'>Email Alerts</div>
                        <div className='Two-space'/>
                    </div>
                }
                <div className='Device-wrapper'>
                    {this.state.loading &&
                            <div>
                                <div className='One-space'/>
                                <div className="Loading-buttonstatus">Loading...</div>
                                <div className='One-space'/>
                            </div>
                    }
                </div>

                {!this.state.loading &&
                    this.state.devicesState.map((device, index) => (     
                        <div key={index}>
                            {device.serial.S !== "marketing" &&
                                <div>
                                <div className='One-space'/>
                                <div className='Device-wrapper'>
                                <div className='One-space'/>
                                {this.userGroup === 'enerstar' &&
                                    <div style={{fontSize: '30px', color: 'white', fontWeight: 'bold'}}>{device.organization.S}</div>
                                }
                                {
                                    <div style={{fontSize: '25px', color: 'white', fontWeight: 'bold'}}>{device.nickname.S}</div>
                                }
                                {device.status.S === 'subscribed' &&
                                    <div>
                                        {index === this.state.index &&
                                            <div>
                                                <div className='One-space'/>
                                                <div style={{fontSize: 25, color: 'white'}}>updating...</div>
                                                <div className='One-space'/>
                                            </div>
                                        }
                                        {index !== this.state.index &&
                                            <div>
                                                <div className='One-space'/>
                                                <div style={{fontSize: '25px', color: '#529857', fontWeight: 'bold'}}>{device.status.S}</div>
                                                <button className= 'Subscribe-button' onClick={() => this.buttonClicked(device, 'unsubscribed', index)}>
                                                    unsubscribe
                                                </button>  
                                            </div>
                                        }   
                                    </div>
                                }
                                {device.status.S === 'unsubscribed' &&
                                    <div>
                                        {index === this.state.index &&
                                            <div>
                                                <div className='One-space'/>
                                                <div style={{fontSize: 25, color: 'white'}}>updating...</div>
                                                <div className='One-space'/>
                                            </div>
    
                                        }
                                        {index !== this.state.index &&
                                            <div>
                                                <div className='One-space'/>
                                                <div style={{fontSize: '25px', color: '#d42d0a', fontWeight: 'bold'}}>{device.status.S}</div>
                                                <button className='Subscribe-button' onClick={() => this.buttonClicked(device, 'subscribed', index)}>
                                                    subscribe
                                                </button>   
                                            </div>
                                        }
        
                                    </div>                                  
                                }
                                <div className='One-space'/>
                                </div>  
                            </div>
                            }
                        </div>                 
                    ))
                }
                <div className = "div-height" />
            </div>
        )
    }
}

export default AlarmSignUp

import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';
import EmailSignup from "../components/EmailSignup"
import IconRanges from "../components/IconRanges"
import IconRanges2 from "../components/IconRangesV2.js"


class Settings extends Component {

    constructor(props){
        super(props);

        this.icondata = [];
        this.deviceControl = false

        const {loc} = props.location.state
        this.local = loc
        
        const {title} = props.location.state
        this.title = title
        
        const {org} = props.location.state
        this.org = org
        
        const {version} = props.location.state
        this.version = version

        if(this.version === 1 || this.version === 2){
            this.deviceControl = true
        }

        if(this.version === 2){
            this.emailAlerts2 = true
        } else {
            this.emailAlerts2 = false
        }

        this.loggedIn = ''

        this.state = {
            icons: [],
            loading: true,
            serial: this.local,
            clickedIcons: false,
            device: '',
            updating: false
        }
        this.getIcons = this.getIcons.bind(this);
    }

    signOut = () => {
        Auth.signOut()
        .then(data => console.log(data))
        .catch(err => console.log(err));
        this.props.history.push('/SignIn');
    }

    async getIcons(){
        const getIcons = `query GetIcons {
            getBlog(id:"${this.blogID}") {
            id
            location
            icons {
                items {
                    id
                    value
                    lowest
                    low
                    high
                    highest
                    order
                }
            }
        }
        }`

        this.icondata = await API.graphql(graphqlOperation(getIcons));
        this.modIcons = this.icondata.data.getBlog.icons.items;
        this.orderedIcons =this.modIcons.sort(function(a, b) {
            return (a.order===null)-(b.order===null) || +(a.order>b.order)||-(a.order<b.order);
        });

        this.setState({
            icons: this.orderedIcons,
            clickedIcons: true
        });
    }


    async buttonClicked(status) {
        this.setState({
            updating: true
        })
        
        const apiNameCheck = 'AlarmList';
        const pathCheck = '/items';

        const mutationInit = {
            queryStringParameters: {
                email: this.deviceAlertInfo.email.S,
                id: this.deviceAlertInfo.id.S,
                username: this.userName,
                nickname: this.deviceAlertInfo.nickname.S,
                serial: this.deviceAlertInfo.serial.S,
                organization: this.deviceAlertInfo.organization.S,
                status: status
            }
        }

        try{
            await API.put(apiNameCheck, pathCheck, mutationInit);
        } catch(error) {
            console.log(error)
            alert('an error occurred accessing the inventory database please try again')
            return
        }

        this.deviceStatus = status

        this.setState({
            deviceStatus: this.deviceStatus,
            updating: false
        })
    }

    async componentDidMount(){
        Auth.currentAuthenticatedUser({
            bypassCache: false
            }).then(user => {
                this.loggedIn = true
            }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );
    

        let noteQuery = this.local

        this.blogCall = await API.graphql(graphqlOperation(queries.listBlogs, {
            limit: 10000,
            filter: {
                location:{
                    eq: noteQuery
                }
            }
        }));

        //set the id to this.blogID
        this.blogID = this.blogCall.data.listBlogs.items[0].id

        let user = await Auth.currentAuthenticatedUser();
        this.email = user.attributes.email
        this.userGroup = user.signInUserSession.accessToken.payload["cognito:groups"][0]
        this.userName = user.username 

        let group = await API.graphql(graphqlOperation(queries.getUser, { userName: this.userName }));
        this.OrgName = group.data.getUser.groupName


        const apiNameCheck = 'AlarmList';
        const pathCheck = '/items';
        let devices = {};

        const myInitCheck = {
            queryStringParameters: {
                name: this.email
            }
        };
        
        try{
            devices = await API.get(apiNameCheck, pathCheck, myInitCheck);
        } catch(error) {
            console.log(error)
            alert('an error occurred')
            return
        }

        let deviceArray = devices.return
        let deviceFound = false
        let deviceInfo = {}

        deviceArray.map((device) => {
            if(device.serial.S === this.local){
                deviceFound = true
                deviceInfo = device
            }
            return(deviceInfo)
        })

        this.deviceAlertInfo = deviceInfo

        if(deviceFound === false && this.version === 2){
            // add device to mailing list and reload devices
            const apiNameCheck = 'AlarmList';
            const pathCheck = '/items';
            let orgSend = ''

            if(this.userGroup === 'enerstar'){
                orgSend = this.org
            } else {
                orgSend = this.orgName
            }

            const mutationInit = {
                queryStringParameters: {
                    email: this.email,
                    username: this.userName,
                    nickname: this.title,
                    serial: this.local,
                    organization: orgSend,
                    status: 'unsubscribed'
                }
            }

            try{
                await API.post(apiNameCheck, pathCheck, mutationInit);
            } catch(error) {
                console.log(error)
                alert('an error occurred accessing the inventory database please try again')
                return
            }
        }

        if(deviceFound){
            this.deviceStatus = deviceInfo.status.S
        } else {
            this.deviceStatus = 'unsubscribed'
        }

        try{
            this.getIcons()
        } catch(error){
            console.log(error)
            alert('error fetching icon range data')
        }
    
        this.setState({
            deviceStatus: this.deviceStatus,
            loading: false
        })
    }

    render() {
        var backIcon = require('../Back-01.png');

        return (
            <div className="App">
                {
                    <div className="Nav-header">
                        <button className="Back-wrap">
                            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Location', state: { loc: this.local, org: this.org, title: this.title, version: this.version} }}>
                                <img onClick={this.stopInterval} className="Back-button" src={backIcon} alt="back" />
                            </Link>
                        </button>
                    </div>
                }

                <div className="Title">Device Settings</div>
                <div className="Settings-wrapper">
                    <div className='One-space'/>
                    <div className="Settings-header">Program Device</div>
                    {this.deviceControl && !this.emailAlerts2 &&
                        <div>
                            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/DeviceSet', state: { loc: this.local, title: this.title, org: this.org, version: this.version } }}>
                                <button className="resend-link">
                                    <div>update</div>
                                </button>
                            </Link>
                            <div className='One-space'/>
                        </div>
                    }
                    {this.deviceControl && this.emailAlerts2 &&
                        <div>
                            <Link style={{ textDecoration: 'none' }} to={{ pathname: '/DeviceSetV2', state: { loc: this.local, title: this.title, org: this.org, version: this.version } }}>
                                <button className="resend-link">
                                    <div>update</div>
                                </button>
                            </Link>
                            <div className='One-space'/>
                        </div>
                    }
                    {!this.deviceControl &&
                        <div className="resend-link">device software does not support programming</div>
                    }
                </div>

                {this.state.loading &&
                    <div className="Loading-button">Loading....</div>
                }

                {!this.state.loading &&
                    <div>
                        {this.deviceControl && !this.emailAlerts2 &&
                            <div>
                                <div className="Settings-header">Email Alerts</div>
                                <EmailSignup serial={this.state.serial}/>
                            </div>
                        }
                        {this.emailAlerts2 && 
                            <div>
                                <div className="Settings-header">Alert Range Settings</div>
                                                              
                                <div style={{fontSize: '20px', color: 'white', fontWeight: 'bold'}}>Email Alert Status:</div>
    
                                {this.state.updating &&
                                    <div className="Loading-button">Updating....</div>
                                }
                                {this.state.deviceStatus === 'subscribed' && !this.state.updating &&
                                    <div>
                                        
                                        <div style={{fontSize: '30px', color: '#529857', fontWeight: 'bold'}}>subscribed</div>
                                        <button className= "Subscribe-button-full" onClick={() => this.buttonClicked('unsubscribed')}>
                                            unsubscribe
                                        </button>  
                                    </div>
                                }
                                {this.state.deviceStatus === 'unsubscribed' && !this.state.updating &&
                                    <div>
                                        
                                        <div style={{fontSize: '30px', color: '#d42d0a', fontWeight: 'bold'}}>unsubscribed</div>
                                        <button className= 'Subscribe-button-full' onClick={() => this.buttonClicked('subscribed')}>
                                            subscribe
                                        </button>  
                                    </div>
                                }
                            </div>
                        }

                        {!this.state.loading && this.emailAlerts2 &&
                            <div>
                                <IconRanges2 icons={this.state.icons} location={this.local} blogID={this.blogID} getIcons={this.getIcons}/>
                            </div>
                        }

                        {!this.state.loading && !this.emailAlerts2 &&
                            <div>
                                <IconRanges icons={this.state.icons} blogID={this.blogID} getIcons={this.getIcons}/>
                            </div>
                        }
                    </div>
                }
                <div className = "div-height" />
            </div>
        )
    }
}

export default Settings

/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries.js';
import { Link } from 'react-router-dom';
import Status from "../components/Status"
import LocationViews from './LocationViews.js';

class Location extends Component {

    constructor(props) {
        super(props);

        //empty props
        this.data = [];
        this.status = [];
        this.noData = true;
        this.errorMessage = '';
        this.deviceError = false;
        this.loggedIn = '';
        this.timeZone = 'America/Chicago';

        // serial number from home
        const {loc} = props.location.state
        this.local = loc
        // nickname from home
        const {title} = props.location.state
        this.title = title
        // org from home none if an org member
        const {org} = props.location.state
        this.org = org
        // device software version number
        const {version} = props.location.state
        this.version = version

        this.state = {
            loading: true,
        }

        // bindings
        this.refreshPage = this.refreshPage.bind(this);
        this.stopInterval = this.stopInterval.bind(this);
    }

    // clear interval for the data call for new data
    stopInterval(event) {
        clearInterval(this.interval);
    }


    // signout current user
    signOut = () => {
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));
    }

    // interval check for new data
    async refreshPage() {
        let queryName = '/items/'+this.local

        if(this.status.timestamps === undefined){
            try{
                this.data = await API.get('dataqueryapi', queryName);
            } catch(error) {
                console.log(error)
            }
        } else {
            let dateNow = new Date();
            let tempTimestamp = this.status.timestamps
            let minLate = 300000;
            let modTimestamp = ''
            if(this.timeZone === 'America/Chicago'){
                modTimestamp = tempTimestamp.replace(/\s/, 'T') 
            } else {
                let dateObject = new Date(tempTimestamp) 
                let changedStamp = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: this.timeZone }).format(dateObject)
                let monthTest = changedStamp.slice(3,5)
                let dayTest = changedStamp.slice(0,2)
                let yearTest = changedStamp.slice(6,10)
                let hourTest = changedStamp.slice(12,14)
                let minuteTest = changedStamp.slice(15,17)
                let milliSeconds = changedStamp.slice(18,20)
        
                modTimestamp = yearTest + "-" + monthTest + "-" + dayTest + "T" + hourTest + ":" + minuteTest + ":" + milliSeconds
            }
       
            if(((dateNow - new Date(modTimestamp)) < minLate)) {
                return
            }
    
            try{
                this.data = await API.get('dataqueryapi', queryName);
            } catch(error) {
                console.log(error)
            }
        }

        
        // check if no data found for device and show message if not
        if(this.data.length === 0){
            this.noData = true;
        } else {
            this.noData = false;
            this.status = this.data.slice(0)[0]
            this.copiedStatus = JSON.parse(JSON.stringify(this.status));
            // check if device is sending error and set its message
            // no input board, device not programmed or both
            if(typeof this.copiedStatus["device_status"] === 'undefined'){
                this.deviceError = false;
            } else {
                this.deviceError = true;
                this.errorMessage = this.copiedStatus["device_status"];
            }
        
        }

        this.setState({
            loading: false
        })

    }

    async componentDidMount(){
        window.scrollTo(0, 0)

        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            this.loggedIn = true
        }
        )
        .catch(error => {
            if(error === 'not authenticated'){
                this.props.history.push('/SignIn');
            }
        }
        );

        let user = await Auth.currentAuthenticatedUser()
        this.userName = user.username

        this.interval = setInterval(this.refreshPage, 30000);
        
        let queryName = '/items/'+this.local
        let blogQuery = this.local
        try{
            this.data = await API.get('dataqueryapi', queryName);
        } catch(error){
            console.log(error)
            if(error.message === 'Network Error'){
                alert('network error check your internet connection')
                return
            } else {
                alert('error loading devices data')
                return
            }
        }
              
        if(this.data.length === 0){
            this.noData = true;
        } else {
            this.noData = false;
            this.status = this.data.slice(0)[0];
            this.copiedStatus = JSON.parse(JSON.stringify(this.status));

            if(typeof this.copiedStatus["device_status"] === 'undefined'){
                this.deviceError = false;
            } else {
                this.deviceError = true;
                this.errorMessage = this.copiedStatus["device_status"];
            }
        }

        // clone object for trimming
        let trimmedStatus = JSON.parse(JSON.stringify(this.status));

        // trim clone
        delete trimmedStatus["timestamps"];
        delete trimmedStatus["groups"];
        delete trimmedStatus["device"];
        delete trimmedStatus["ttl"];

        //Call the blog based on location to get the id
        try{
            let blogCall = await API.graphql(graphqlOperation(queries.listBlogs,{limit: 1000, filter:{location:{eq: blogQuery}}}));
            //set the id to this.blogID
            this.blogID = blogCall.data.listBlogs.items[0].id
        } catch(error) {
            console.log(error)
        }

        const getIcons = `query GetIcons { getBlog(id:"${this.blogID}") {id location icons { items { id value lowest low high highest order }}}}`

        this.icondata = await API.graphql(graphqlOperation(getIcons));
        this.modIcons = this.icondata.data.getBlog.icons.items;
        
        // order icons based on icon settings
        let orderedIcons = this.modIcons.sort(function(a, b) {
            return (a.order===null)-(b.order===null) || +(a.order>b.order)||-(a.order<b.order);
        });

        //clean up icons
        var cleanedIcons = orderedIcons.map(function(elem) {
            return {
                value: elem.value,
                order: elem.order,
            }
        });

        // put status in an array of array
        var sortable = [];
        for (var value in trimmedStatus) {
            sortable.push([value, trimmedStatus[value]]);
        };

        //map through ordered sorted icons when matched with status reading add to array
        var orderedArray = []

        cleanedIcons.map(function(item){
            for (let index = 0; index < sortable.length; ++index) {
                if(item.value === sortable[index][0]){
                orderedArray.push(sortable[index])
                }
            }
            return "checked";
        });

        // combine arrays into one object
        trimmedStatus = orderedArray.reduce((result, [key, value]) => {
            result[key] = value;
            return result;
        }, {});

        //check for users timezone offset compared to central times offset
        let changedCentral = new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'long', timeZone: 'America/Chicago' }).format()
        let offsetCentral = Number(changedCentral.slice(-1))
        let localDate = new Date()
        let localOffset = localDate.getTimezoneOffset() / 60

        if(offsetCentral === localOffset){
            this.timeZone = 'America/Chicago'
        } else {
            let offsetDifference = offsetCentral - localOffset
            if(offsetDifference === 1){
                this.timeZone = 'America/Halifax'
            }
            if(offsetDifference === -1){
                this.timeZone = 'America/Los_Angeles'
            }
            if(offsetDifference === -2){
                this.timeZone = 'America/Anchorage'
            }
        }

        this.setState({
            loading: false
        });

    }

    UNSAFE_componentWillUnmount() {
        //cant perform react state update on unmounted component
        clearInterval(this.interval);
            this.setState = (state,callback)=>{
                return;
            };
    }


    render() {

        var backIcon = require('../Back-01.png');
        var settingsIcon = require('../Settings-01.png')

        return (
            <div className="App">
                {
                    <div className="Nav-header">
                        {
                            <Link style={{textDecoration: 'none'}} to="/">
                                <button onClick={this.stopInterval} className="Back-wrap">
                                    <img className="Back-button" src={backIcon} alt="back"/>
                                </button>
                            </Link>
                        }
                        <Link style={{ textDecoration: 'none' }} to={{ pathname: '/Settings', state: { loc: this.local, title: this.title, org: this.org, version: this.version } }}>
                            <button onClick={this.stopInterval} className="Settings-button">
                                <img className="Settings-icon" src={settingsIcon} alt="settings" />
                            </button>
                        </Link>
                    </div>
                }
                {
                    <div>
                        {this.org !== 'none' &&
                            <div>
                                <div className="location-org">{this.org}</div>
                                <div className="location-dev">{this.title}</div>
                            </div>
                        }    
                        
                        {this.org === 'none' &&
                            <div className="location-org">{this.title}</div>
                        }
                        <div className='One-space'/>
                    </div>
                }

                <Status data={this.status} icons={this.modIcons} noData={this.noData} timezone={this.timeZone} version={this.version}/>

                {
                    <LocationViews data={this.data} icons={this.modIcons} serial={this.local} blogID={this.blogID} userName={this.userName} title={this.title} org={this.org} version={this.version} noData={this.noData} timezone={this.timeZone}/>
                }         

                <div className = "div-height" />
            </div>
        );
    }
}

export default Location
